import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Layout from "../../components/layout";
import { ErrMsg, SuccessMsg } from "../../components/message";
import ConfirmBox from "../../components/confirmBox";
import Tabs from "../../components/tabs";
import Loading from "../../components/loading";

import { CurrentUserContext } from "../../providers/auth";
import Links, { getAdminLinks } from "../../utils/links";
import UserUtil from "../../utils/user";
import Services, { hasAuthError } from "../../services";

const ParametersPage = () => {
    const [checkCount, setCheckCount] = useState(0);

    const forceCheckSession = () => {
        setCheckCount(checkCount + 1);
    };

    return (
        <Layout
            seoTitle="System Parameters"
            link={Links.Admin}
            permissionValidator={UserUtil.hasSysAdminPrivileges}
            forceCheckSessionCount={checkCount}
        >
            <Parameters forceCheckSession={forceCheckSession} />
        </Layout>
    );
};

export default ParametersPage;

const parsePositiveIntStr = (newVal) => {
    const val = parseInt(newVal);
    return isNaN(val) ? null : val <= 0 ? null : val.toString();
};

const parsePositiveIntStrInRange = (newVal, min, max) => {
    const valStr = parsePositiveIntStr(newVal);
    if (valStr === null) {
        return null;
    }
    const val = parseInt(valStr);
    return val < min || val > max ? null : valStr;
};

const parsePositiveFloatStr = (newVal) => {
    const val = parseFloat(newVal);
    return isNaN(val) ? null : val <= 0 ? null : val.toString();
};

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const parseEmailRecipientStr = (newVal) => {
    const emails = newVal
        .split(",")
        .map((d) => d.trim())
        .filter((d, i, a) => i === a.indexOf(d) && d !== "");
    for (const email of emails) {
        if (!EMAIL_REGEX.test(email)) {
            return null;
        }
    }
    const emailStr = emails.join(",");
    return emailStr === "" ? null : emailStr;
};

const getPercent = (v, base) => Math.round((v * 100) / base);

const ValidCounts = {
    MaxHourly: 60,
    MaxDaily: 24,
    MaxWeekly: 168,
    MaxMonthly: 720,
    MaxYearly: 8760
};

const DataHealthCheckFrequencyOptions = {
    daily: "Daily at 00:00 (HKT)",
    twice_a_day: "Half daily at 00:00, 12:00 (HKT)",
    every_6_hr: "Every 6 hours at 00:00, 06:00, 12:00, etc. (HKT)",
    every_4_hr: "Every 4 hours at 00:00, 04:00, 08:00, etc. (HKT)",
    every_2_hr: "Every 2 hours at 00:00, 02:00, 04:00, etc. (HKT)",
    hourly: "Hourly at XX:00",
    twice_an_hour: "Half hourly at XX:00, XX:30",
    every_15_min: "Every 15 minutes at XX:00, XX:15, XX:30, XX:45",
    stop: "Stop checking latest data received time"
};

const ParamHelperDict = {
    IDLE_TIMEOUT_MINUTES: {
        order: 1,
        label: "Idle timeout (min)",
        hint: "The value should be a positive integer.",
        format: parsePositiveIntStr
    },
    LOGIN_ATTEMPT_DURATION_MINUTES: {
        order: 2,
        label: "Login attempt duration (min)",
        hint: "The value should be a positive integer.",
        format: parsePositiveIntStr
    },
    MAX_FAILED_LOGIN_ATTEMPTS: {
        order: 3,
        label: "Max failed login attempts",
        hint: "The value should be a positive integer.",
        format: parsePositiveIntStr
    },
    NO_UNIT_CONVERSION_FACTOR: {
        order: 4,
        label: "Unit conversion factor - NO",
        hint: "The value should be a positive number.",
        format: parsePositiveFloatStr
    },
    NO2_UNIT_CONVERSION_FACTOR: {
        order: 5,
        label: "Unit conversion factor - NO2",
        hint: "The value should be a positive number.",
        format: parsePositiveFloatStr
    },
    PM25_UNIT_CONVERSION_FACTOR: {
        order: 6,
        label: "Unit conversion factor - PM2.5",
        hint: "The value should be a positive number.",
        format: parsePositiveFloatStr
    },
    HOURLY_AVERAGE_MIN_VALID_COUNT: {
        order: 7,
        label: "Min. valid minute data count - Hourly average",
        hint: `The value should be a positive integer <= ${ValidCounts.MaxHourly}.`,
        format: (v) => parsePositiveIntStrInRange(v, 1, ValidCounts.MaxHourly),
        getPercent: (v) => getPercent(v, ValidCounts.MaxHourly)
    },
    DAILY_AVERAGE_MIN_VALID_COUNT: {
        order: 8,
        label: "Min. valid hourly data count - Daily average",
        hint: `The value should be a positive integer <= ${ValidCounts.MaxDaily}.`,
        format: (v) => parsePositiveIntStrInRange(v, 1, ValidCounts.MaxDaily),
        getPercent: (v) => getPercent(v, ValidCounts.MaxDaily)
    },
    WEEKLY_AVERAGE_MIN_VALID_COUNT: {
        order: 9,
        label: "Min. valid hourly data count - Weekly average",
        hint: `The value should be a positive integer <= ${ValidCounts.MaxWeekly}.`,
        format: (v) => parsePositiveIntStrInRange(v, 1, ValidCounts.MaxWeekly),
        getPercent: (v) => getPercent(v, ValidCounts.MaxWeekly)
    },
    MONTHLY_AVERAGE_MIN_VALID_COUNT: {
        order: 10,
        label: "Min. valid hourly data count - Monthly average",
        hint: `The value should be a positive integer <= ${ValidCounts.MaxMonthly}.`,
        format: (v) => parsePositiveIntStrInRange(v, 1, ValidCounts.MaxMonthly),
        getPercent: (v) => getPercent(v, ValidCounts.MaxMonthly)
    },
    YEARLY_AVERAGE_MIN_VALID_COUNT: {
        order: 11,
        label: "Min. valid hourly data count - Yearly average",
        hint: `The value should be a positive integer <= ${ValidCounts.MaxYearly}.`,
        format: (v) => parsePositiveIntStrInRange(v, 1, ValidCounts.MaxYearly),
        getPercent: (v) => getPercent(v, ValidCounts.MaxYearly)
    },
    DATA_HEALTH_CHECK_FREQUENCY: {
        order: 12,
        label:
            "Frequency of checking latest lamppost sensor data received time",
        hint: "This value should not be empty.",
        format: (v) =>
            Object.keys(DataHealthCheckFrequencyOptions).includes(v) ? v : null,
        isString: true,
        options: {
            values: Object.keys(DataHealthCheckFrequencyOptions),
            textFormat: (v) => DataHealthCheckFrequencyOptions[v] || v
        }
    },
    LAMPPOST_NO_DATA_PERIOD_THRESHOLD_MINUTES: {
        order: 13,
        label:
            "Minimum no sensor data time period (min) for sending alert email",
        hint: "The value should be a positive integer.",
        format: parsePositiveIntStr,
        showCondition: {
            dependedParam: "DATA_HEALTH_CHECK_FREQUENCY",
            showCheck: (v) => v !== "stop"
        }
    },
    NO_DATA_ALERT_EMAIL_RECIPIENTS: {
        order: 14,
        label: "Recipients of no lamppost sensor data alert email",
        hint:
            "The value should be a valid email address or a comma separated string of multiple email addresses.",
        format: parseEmailRecipientStr,
        isString: true,
        showCondition: {
            dependedParam: "DATA_HEALTH_CHECK_FREQUENCY",
            showCheck: (v) => v !== "stop"
        }
    }
};

const formatDisplayValue = (helper, value, quoteString = false) =>
    ((quoteString && helper.isString && '"') || "") +
    (helper.options && helper.options.textFormat
        ? helper.options.textFormat(value)
        : value) +
    ((quoteString && helper.isString && '"') || "");

const Parameters = ({ forceCheckSession }) => {
    const currentUser = useContext(CurrentUserContext);
    const systemService = Services(currentUser).system;

    const [loading, setLoading] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [updateErr, setUpdateErr] = useState("");
    const [updateOk, setUpdateOk] = useState(false);
    const [params, setParams] = useState([]);

    const handleError = (err) => {
        if (hasAuthError(err)) {
            forceCheckSession();
        } else {
            console.error(err);
        }
        setUpdateErr(
            err.message ? err.message : "Cannot manage system parameters."
        );
    };

    const reload = () => {
        setLoading(true);
        setShowConfirm(false);
        systemService
            .getParameters()
            .then((d) => {
                let newParams = d.parameters
                    .filter((p) => ParamHelperDict[p.name])
                    .map((p) =>
                        Object.assign({}, p, {
                            helper: ParamHelperDict[p.name],
                            edited: false,
                            editedValue: null,
                            err: ""
                        })
                    );
                newParams.sort((a, b) => a.helper.order - b.helper.order);
                setParams(newParams);
            })
            .catch((err) => {
                setParams([]);
                handleError(err);
            })
            .finally(() => setLoading(false));
    };

    const onEditParam = (idx, newVal) => {
        let newParams = params.map((p) => Object.assign({}, p));
        let curParam = newParams[idx];
        const val = !!curParam.helper.isString
            ? newVal
            : newVal.replace(/[^\d.]/g, "");
        if (val !== curParam.value) {
            curParam.edited = true;
            curParam.editedValue = val;
        } else {
            curParam.edited = false;
            curParam.editedValue = null;
        }
        setParams(newParams);
    };

    const showParam = (param) => {
        if (param.helper.showCondition) {
            const depended = params.find(
                (d) => d.name === param.helper.showCondition.dependedParam
            );
            if (depended) {
                const value = depended.edited
                    ? depended.editedValue
                    : depended.value;
                return param.helper.showCondition.showCheck(value);
            }
        }
        return true;
    };

    const confirmUpdate = () => {
        setUpdateOk(false);
        let newParams = params.map((p) => {
            let newP = Object.assign({}, p, { err: "" });
            if (p.edited) {
                const newVal = p.helper.format(p.editedValue);
                if (newVal === null) {
                    newP.err = p.helper.hint;
                } else if (newVal === newP.value) {
                    newP.edited = false;
                    newP.editedValue = null;
                } else {
                    newP.editedValue = newVal;
                }
            }
            return newP;
        });
        setParams(newParams);
        if (newParams.find((p) => p.err && showParam(p))) {
            return;
        }
        if (!newParams.find((p) => p.edited && showParam(p))) {
            return;
        }
        setShowConfirm(true);
    };

    const update = () => {
        setUpdating(true);
        systemService
            .updateParameters(
                params
                    .filter((p) => p.edited && showParam(p))
                    .map((p) =>
                        Object.assign(
                            {},
                            { name: p.name, value: p.editedValue }
                        )
                    )
            )
            .then(() => {
                setUpdateOk(true);
                reload();
            })
            .catch((err) => handleError(err))
            .finally(() => setUpdating(false));
    };

    const reset = () => {
        setUpdateOk(false);
        setParams(
            params.map((p) =>
                Object.assign({}, p, {
                    edited: false,
                    editedValue: null,
                    err: ""
                })
            )
        );
    };

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        reload();
    }, [currentUser]);

    const ConfirmUpdate = () => (
        <ConfirmBox action={update} cancelAction={() => setShowConfirm(false)}>
            <div className="text-base">
                Are you sure to update following system parameters in&nbsp;
                <span className="green">green color</span>?
            </div>
            <div className="border-b my-4">
                {params
                    .filter((p) => p.edited && showParam(p))
                    .map((p, i) => (
                        <div
                            className="flex flex-row items-center py-1 border-t"
                            key={i}
                        >
                            <div className="w-1/2 label-r">
                                {p.helper.label}
                            </div>
                            <div className="w-1/2 break-words">
                                From{" "}
                                {formatDisplayValue(p.helper, p.value, true)}{" "}
                                {p.helper.getPercent && (
                                    <span>
                                        (~
                                        {p.helper.getPercent(p.value)}
                                        %)
                                    </span>
                                )}{" "}
                                to&nbsp;
                                <span className="green font-semibold">
                                    {formatDisplayValue(
                                        p.helper,
                                        p.editedValue,
                                        true
                                    )}{" "}
                                    {p.helper.getPercent && (
                                        <span>
                                            (~
                                            {p.helper.getPercent(p.editedValue)}
                                            %)
                                        </span>
                                    )}
                                </span>
                            </div>
                        </div>
                    ))}
            </div>
        </ConfirmBox>
    );

    return (
        <>
            <Tabs links={getAdminLinks(Links.SystemParameters)} />
            {loading && <Loading />}
            <div className={"my-10" + (loading ? " hidden" : "")}>
                {params.map(
                    (p, i) =>
                        showParam(p) && (
                            <div className="flex mt-4" key={i}>
                                <div className="w-1/3 label-r">
                                    {p.helper.label}
                                </div>
                                <div className="w-1/2">
                                    {p.helper.options &&
                                    p.helper.options.values ? (
                                        <select
                                            className={
                                                "input" +
                                                (p.edited
                                                    ? " green font-semibold"
                                                    : "")
                                            }
                                            style={{ width: "300px" }}
                                            type="text"
                                            title={p.helper.hint}
                                            onChange={(e) =>
                                                onEditParam(i, e.target.value)
                                            }
                                            value={
                                                p.edited
                                                    ? p.editedValue
                                                    : p.value
                                            }
                                        >
                                            {p.helper.options.values.map(
                                                (o, oi) => (
                                                    <option key={oi} value={o}>
                                                        {p.helper.options.textFormat(
                                                            o
                                                        )}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    ) : (
                                        <input
                                            className={
                                                "input" +
                                                (p.edited
                                                    ? " green font-semibold"
                                                    : "")
                                            }
                                            style={{ width: "300px" }}
                                            type="text"
                                            title={p.helper.hint}
                                            onChange={(e) =>
                                                onEditParam(i, e.target.value)
                                            }
                                            value={
                                                p.edited
                                                    ? p.editedValue
                                                    : p.value
                                            }
                                        />
                                    )}

                                    {p.helper.getPercent && (
                                        <span className="ml-2">
                                            (~
                                            {p.helper.getPercent(
                                                p.edited
                                                    ? p.editedValue
                                                    : p.value
                                            )}
                                            %)
                                        </span>
                                    )}
                                    {p.edited && (
                                        <span className="ml-6 bg-gray-300 px-2">
                                            Old value:{" "}
                                            {formatDisplayValue(
                                                p.helper,
                                                p.value
                                            )}
                                            {p.helper.getPercent && (
                                                <span>
                                                    ,&nbsp;~
                                                    {p.helper.getPercent(
                                                        p.value
                                                    )}
                                                    %
                                                </span>
                                            )}
                                        </span>
                                    )}
                                    <ErrMsg msg={p.err} />
                                </div>
                            </div>
                        )
                )}
                <div className="flex mt-6 pb-12">
                    <div className="w-1/3 h-1"></div>
                    <div className="w-1/2">
                        <div className={updating ? "hidden" : ""}>
                            <button
                                className="btn-primary w-1/3 mr-4"
                                onClick={confirmUpdate}
                            >
                                Update
                            </button>
                            <button
                                className="btn-secondary w-1/3"
                                onClick={reset}
                            >
                                Reset
                            </button>
                        </div>
                        <SuccessMsg
                            msg={
                                updateOk
                                    ? "Updated system parameters successfully."
                                    : ""
                            }
                        />
                        <ErrMsg msg={updateErr} />
                        {updating && (
                            <Loading cssClass="inline-block" word="Updating" />
                        )}
                    </div>
                </div>
            </div>
            {showConfirm && <ConfirmUpdate />}
        </>
    );
};

Parameters.propTypes = {
    forceCheckSession: PropTypes.func.isRequired
};
